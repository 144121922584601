import React, {Component} from 'react';
import {Container} from 'reactstrap'
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import styled from "styled-components";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {media} from "utils/Media"

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const GalleryItem = styled.div`
  width: 100%;
  padding: 1rem 0.5rem;

  @media ${media.sm} {
    width: 50%;
  }

  @media ${media.lg} {
    width: 33%;
  }

  img {
    width: 330px !important;
    margin: 0 auto !important;
    max-width: 100% !important;
    height: auto !important;
  }
`

const Content = styled.div`
  text-align: center;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

class PhotosBlock extends Component {
    constructor(props) {

        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            modalOpen: false,
        }

    }

    toggleModal = (e) => {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen
        });
    };

    render() {
        const {isOpen, photoIndex} = this.state;
        let images = [];
        // console.log(this.props)
        const showGallery = this.props.photos.map((item, i) => {
            let obj = {}
            obj.src = item.image.childImageSharp.gatsbyImageData.images.fallback.src
            // obj.caption = item.node.caption
            images.push(obj)
            return (
                <GalleryItem>
                    <div
                        tabIndex={i} role="button" aria-pressed="false"
                        onClick={() => this.setState({photoIndex: i, isOpen: true})}
                        onKeyDown={() => this.setState({photoIndex: i, isOpen: true})}
                        className="w-100"
                    >
                        <GatsbyImage image={getImage(item.thumb)}
                                     alt=""
                                     placeholder="none"
                                     onClick={() => this.setState({photoIndex: i, isOpen: true})}
                                     className="d-block img-fluid"
                        />
                    </div>
                </GalleryItem>
            )
        })

        return (
            <Content className="">
                <Container>
                    <GalleryWrapper>
                        {showGallery}
                    </GalleryWrapper>
                </Container>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex].src}
                        nextSrc={images[(photoIndex + 1) % images.length].src}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
                        imageCaption=""
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </Content>
        );
    }
}

export default PhotosBlock

