import React, {Component, useState} from "react";
import Layout from "components/Layout/Layout";
import Seo from "utils/Seo";
import styled from "styled-components";
import PageHeader from "components/shared/PageHeader";
import VideoBlock from "../components/PhotosAndVideosPage/VideoBlock";
import PhotosBlock from "../components/PhotosAndVideosPage/PhotosBlock";
import {media} from "../utils/Media";
import {graphql} from "gatsby";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`;
const TabToggle = styled.nav`
  width: 100%;
  p {
    display: block;
  }
  > div {
    width: 90%;
    margin: 2rem auto;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;

    h3 {
      cursor: pointer;
      font-weight: 500;
      font-family: ${(props) => props.theme.font.family.boldTall} !important;
      font-size: 2.2rem !important;
      text-transform: uppercase;
      @media (min-width: 375px) {
        font-size: 2.5rem !important;
      }
      @media (min-width: 414px) {
        font-size: 3rem !important;
      }
      @media ${media.md} {
        font-size: 3.5rem !important;
      }
      @media ${media.lg} {
        font-size: 5rem !important;
      }
    }

    @media ${media.lg} {
      gap: 2rem;
    }

    .active {
      color: ${(props) => props.theme.colors.secondary} !important;
    }
  }
`;

const PageSubHeading = styled.section`
  width: 100%;
  background: ${(props) => props.theme.colors.grey};
  padding: 2rem 0;

  h1 {
    text-align: center;
    font-weight: 500;
    font-family: ${(props) => props.theme.font.family.boldTall} !important;
    margin-bottom: 0;
    font-size: 3rem !important;
    @media ${media.md} {
      font-size: 5rem !important;
    }
  }

  p {
    margin: .5rem auto 0;
    font-size: 0.85rem !important;
    @media ${media.md} {
      font-size: 1rem !important;
    }
  }
`;

const PhotoPage = ({data}) => {
  //? TRUE = Show Gallery
  //? FALSE =  Show Rehearsal shots
  const [toggle, setToggle] = useState("production");
  const [photoshoot, rehearsal, production] = data.gallery.edges;
  return (
    <Layout>
      <Seo slug="/gallery/" title="Gallery" description="The official website for Grease The Musical. The world's best-loved musical is back in London’s West End! Grittier and more electrifying than ever before. | Gallery "/>
      <PageHeader tour={true} />
      <PageSubHeading>
        <h1>GALLERY</h1>
      </PageSubHeading>
      <TabToggle>
        <div>
          <h3
            className={toggle === "production" && "active"}
            onClick={() => setToggle("production")}
          >
            PRODUCTION
          </h3>
          {/* <h3
            className={toggle === "rehearsal" && "active"}
            onClick={() => setToggle("rehearsal")}
          >
            REHEARSALS
          </h3>
          
          <h3
            className={toggle === "photoshoot" && "active"}
            onClick={() => setToggle("photoshoot")}
          >
            PHOTOSHOOT
          </h3> */}
        </div>
      </TabToggle>
      <Container>
      {/* <p className="block">Photos from 2023 West End cast</p> */}
        {/* {toggle === "photoshoot" &&
          <PhotosBlock photos={photoshoot.node.photos}/>
        }
        {toggle === "rehearsal" &&
          <PhotosBlock photos={rehearsal.node.photos}/>
        } */}
        {toggle === "production" &&
          <PhotosBlock photos={production.node.photos}/>
        }
      </Container>
    </Layout>
  );
};
export default PhotoPage;

export const PHOTOQUERY = graphql`
  query PHOTOQUERY {
    gallery: allGalleryJson {
      edges {
        node {
          type
          id
          photos {
            thumb {
              childImageSharp {
                gatsbyImageData(width: 330, quality: 100)
              }
            }
            image {
              childImageSharp {
                gatsbyImageData(width: 1920, quality: 100)
              }
            }
            caption
          }
        }
      }
    }
  }
`;
