import React from 'react'
import {Modal, ModalBody} from 'reactstrap'
import styled from "styled-components";
import {media} from "utils/Media"

const StyledModal = styled(Modal)`
    position: relative;
    margin-top: 3rem;
    
    .modal-body {
        padding: 0;
    }
`

const ButtonClose = styled.button`
	position: absolute;
    background-color: ${props => props.theme.colors.black};
    border: 0;
    z-index: 10;
    right: 0;
    top: -2rem;
    width: 32px;
    height: 32px;

    @media ${media.lg} {
        top: -3rem;
        width: 48px;
        height: 48px;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 75%;
        height: 2px;
        background-color: #fff;
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
`

const YoutubeModal = (props) => (
    <StyledModal isOpen={props.isOpen} className="youtube-modal" toggle={props.toggleModal}
           centered={true}>
        <ButtonClose onClick={props.toggleModal}>
            <span className="sr-only">Close</span>
        </ButtonClose>
        <ModalBody>
            <div className="video-wrapper">
                <iframe className="video-frame" width="100%" src={`https://www.youtube.com/embed/${props.videoId}?rel=0&amp;autoplay=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
            </div>
        </ModalBody>
    </StyledModal>
);

export default YoutubeModal